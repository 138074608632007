import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "feature-group mx-5" }
const _hoisted_2 = { class: "feature-group mx-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeatureCard = _resolveComponent("FeatureCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
        return _createVNode(_component_FeatureCard, {
          key: i,
          title: _ctx.$t(`home.features[${i-1}].title`),
          description: _ctx.$t(`home.features[${i-1}].description`)
        }, null, 8, ["title", "description"])
      }), 64))
    ]),
    _createElementVNode("section", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
        return _createVNode(_component_FeatureCard, {
          key: i,
          title: _ctx.$t(`home.features[${i+2}].title`),
          description: _ctx.$t(`home.features[${i+2}].description`)
        }, null, 8, ["title", "description"])
      }), 64))
    ])
  ], 64))
}
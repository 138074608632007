
import FeatureCard from '@/components/FeatureCard.vue';
import { Options, Vue } from 'vue-class-component';

@Options({
    components: {
        FeatureCard
    },
})
export default class HomeView extends Vue { }

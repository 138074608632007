
import { useI18n } from "vue-i18n";
import { defineComponent } from 'vue';
import PageFooter from './components/PageFooter.vue';
import LangSelect from './components/LangSelect.vue';
import actionAnimeRender from './lib/actionanime';

export default defineComponent({
  components: { PageFooter, LangSelect },
  data() {
    return {
      isOnTop: true
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.isOnTop = window.scrollY == 0;
    });
    actionAnimeRender();
  },
  setup() {
    const { locale } = useI18n();
    const localToTitle: { [key: string]: string } = {
      "zh-TW": "中文繁體",
      "en-US": "English",
      "ja-JP": "日本",
      "zh-CN": "中文简体"
    };
    const langSelectOption: { title: string, value: string }[] = [
      {
        title: "中文繁體",
        value: "zh-TW"
      },
      {
        title: "English",
        value: "en-US"
      },
      {
        title: "日本",
        value: "ja-JP"
      },
    ];
    return {
      locale,
      langSelectOption,
      localToTitle
    };
  },
  watch: {
    locale(newlocale: string) : void {
      localStorage.setItem("locale", newlocale);
    },
    $route() : void {
      actionAnimeRender();
    }
  }
});

import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LangSelect = _resolveComponent("LangSelect")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PageFooter = _resolveComponent("PageFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", {
      class: _normalizeClass({ active: !_ctx.isOnTop })
    }, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("nav.home")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/about" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("nav.about")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_LangSelect, {
        modelValue: _ctx.locale,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.locale) = $event)),
        default: _ctx.localToTitle[_ctx.locale],
        options: _ctx.langSelectOption
      }, null, 8, ["modelValue", "default", "options"])
    ], 2),
    _createVNode(_component_router_view),
    _createVNode(_component_PageFooter)
  ], 64))
}


import HomeBanner from '@/components/HomeBanner.vue';
import { Options, Vue } from 'vue-class-component';
import FeatureCardGroup from '@/components/FeatureCardGroup.vue';
@Options({
  components: {
    HomeBanner, FeatureCardGroup
  },
})
export default class HomeView extends Vue { }
